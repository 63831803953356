import domReady from '../../shared/utils/dom_ready';
import scrollit from '../../shared/utils/scrollit';

domReady(() => {
  const initializeQuiz = () => {
    const quiz = document.querySelector('.quiz-stage');
    if (!quiz) return;
    const quizClone = quiz.cloneNode(true);
    const cards = document.querySelectorAll('.quiz-card');
    const negativeButton = (item) => item.querySelector('[negative]');
    const positiveButton = (item) => item.querySelector('[positive]');
    const neutralButton = (item) => item.querySelector('[neutral]');
    const quizResultContainer = document.querySelector('.quiz-result');
    const quizResultItems = quizResultContainer.querySelectorAll(
      '.quiz-result__item'
    );
    const results = new Array(cards.length);
    const restart = document.querySelector('.quiz-restart');
    const facebookLink = quizResultContainer.querySelector('.facebook-link');

    const handleStageHeight = (height) => {
      quiz.style.height = `${quiz.clientHeight}px`;
      setTimeout(() => {
        quiz.style.height = `${height}px`;
      }, 100);
    };

    setTimeout(() => {
      handleStageHeight(quiz.clientHeight);
    }, 1000);

    const buildShareLink = (type) => {
      const answerSet = type.join('');
      const parameterString = `?p=quiz%26q=${answerSet}`;
      const urlString = `${document.location.origin}${document.location.pathname}`;
      const sharepicUrl = `${urlString}${parameterString}`;
      const dataFacebookMessage = facebookLink.dataset.facebookMessage;
      const facebookMessage = encodeURIComponent(dataFacebookMessage);
      facebookLink.href = `https://www.fb.com/sharer/sharer.php?u=${sharepicUrl}&quote=${facebookMessage}`;
    };

    const showResults = (type) => {
      quizResultItems.forEach((item, i) => {
        const answer = ['neutral', 'positive', 'negative'][type[i]];
        item.classList.add(answer);
      });

      setTimeout(() => {
        quizResultContainer.classList.add('is-active');
        handleStageHeight(quizResultContainer.clientHeight);
        setTimeout(() => {
          quiz.style.height = 'auto';
        }, 1000);
      }, 600);

      buildShareLink(type);
    };

    const restartQuiz = () => {
      quiz.parentNode.appendChild(quizClone);
      scrollit(quiz.parentNode, 400, true, 120);
      quiz.remove();
      initializeQuiz();
    };

    cards.forEach((item, i) => {
      const negative = negativeButton(item);
      const positive = positiveButton(item);
      const neutral = neutralButton(item);

      negative.addEventListener('click', () => {
        results[i] = 2;
        item.classList.add('exit-negative');
        setTimeout(() => {
          item.remove();
        }, 400);
        if (i > cards.length - 2) {
          showResults(results);
        }
      });

      positive.addEventListener('click', () => {
        results[i] = 1;
        item.classList.add('exit-positive');
        setTimeout(() => {
          item.remove();
        }, 400);
        if (i > cards.length - 2) {
          showResults(results);
        }
      });

      neutral.addEventListener('click', () => {
        results[i] = 0;
        item.classList.add('exit-positive');
        setTimeout(() => {
          item.remove();
        }, 400);
        if (i > cards.length - 2) {
          showResults(results);
        }
      });
    });

    restart.addEventListener('click', restartQuiz);
  };

  initializeQuiz();
});
